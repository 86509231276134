.styled-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  min-width: 400px;
  width: 100%;
  margin: 25px 0;
  font-size: 0.9em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #2d53fc;
  color: #fff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #ddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #2d53fc;
}

/* .styled-table tr:hover {background-color: #ddd;} */

button {
  color: #444444;
  background: #F3F3F3;
  border: 1px #DADADA solid;
  padding: 5px 10px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 9pt;
  outline: none;
  border-radius: 10px;
}

button:hover {
  border: 1px #C6C6C6 solid;
  box-shadow: 1px 1px 1px #EAEAEA;
  color: #333333;
  background: #F7F7F7;
}

button:active {
  box-shadow: inset 1px 1px 1px #DFDFDF;   
}

button.save-mall {
  color: white;
  background: #47c200;
  border: 1px #008a07 solid;
  box-shadow: inset 0 1px 0 #80fb9f;
}

button.save-mall:hover {
  border: 1px #2c9c22 solid;
  box-shadow: 0 1px 1px #EAEAEA, inset 0 1px 0 #5af161;
  background: #039617;
}

button.delete-mall {
  color: white;
  background: #e40000;
  border: 1px #8a0000 solid;
  box-shadow: inset 0 1px 0 #fb8080;
}

button.delete-mall:hover {
  border: 1px #9c2222 solid;
  box-shadow: 0 1px 1px #EAEAEA, inset 0 1px 0 #f15a5a;
  background: #960303;
}

/* table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;} */

/* table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #0317c2;
  color: white;
} */